import "./App.css";

function App() {
  return (
    <div className="App">
      <iframe
        title="PDF Viewer"
        src="pdf/brochure.pdf"
        style={{ width: "100%", height: "100%", border: "none" }}
      />
    </div>
  );
}

export default App;
